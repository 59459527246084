import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="section-content footer-container">
        <div>@ 2024 Solo Farming. All rights reserved.</div>
        <div className="policy">
          <a
            href="https://docs.solofarming.xyz/whitepaper/support/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            href="https://docs.solofarming.xyz/whitepaper/support/terms-of-service"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
