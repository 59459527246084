import React from "react";
import "./Economy.css";
import sfs from "../assets/sfs.gif";
import sft from "../assets/sft.gif";
import economyDiagram from "../assets/economy-diagram.png";

function Economy() {
  return (
    <div className="economy">
      <div className="section-content economy-container">
        <h1>Economy</h1>
        <div className="economy-info">
          <p>
            Solo Farming features the best and most comprehensive gaming economy
            cycle, wherein each mastery, such as farming, mining, fishing, etc.,
            plays a vital role.
          </p>
        </div>

        <div className="tokens-container">
          <div className="token-info">
            <img src={sfs} alt="sfs token" />
            <h3>Solo Farming Silver</h3>
            <p>
              The basic cycle of the game occurs through the Solo Farming
              Silver. With it, you can buy seeds, plant, harvest, and then sell
              to acquire more Silver.
            </p>
          </div>
          <div className="token-info">
            <img src={sft} alt="sft token" />
            <h3>Solo Farming Token</h3>
            <p>
              SFT is the primary token of the game, used in auctions, crafting,
              and premium shops to acquire new items. Additionally, you can
              stake it to earn rewards.
            </p>
          </div>
        </div>
        <div className="economy-diagram">
          <img src={economyDiagram} alt="economy diagram"></img>

          <div>
            <button
              className="button button-light"
              onClick={() =>
                window.open(
                  "https://docs.solofarming.xyz/whitepaper/fundamentals/economy",
                  "_blank"
                )
              }
            >
              Whitepaper - Economy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Economy;
