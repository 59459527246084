import React, { useEffect } from "react";
import "./Header.css";
import ground from "../assets/ground.png";
import groundLeft from "../assets/ground-left.png";
import groundRight from "../assets/ground-right.png";
import house from "../assets/house.gif";
import npcFarming from "../assets/npc-watering.gif";
import npcMining from "../assets/npc-mining.gif";
import npcLivestock from "../assets/npc-livestock.gif";
import npcFishing from "../assets/npc-fishing.gif";
import cloud1 from "../assets/cloud1.png";
import cloud2 from "../assets/cloud2.png";
import train from "../assets/train.gif";
import logo from "../assets/logo.png";
import headerMobile from "../assets/header-mobile.png";

const HeaderParallax = () => {
  useEffect(() => {
    const handleScroll = () => {
      const parallaxLayers = document.querySelectorAll(".parallax-layer");
      parallaxLayers.forEach((layer) => {
        const speed = parseFloat(layer.getAttribute("data-speed") ?? "0");
        if (layer instanceof HTMLElement) {
          layer.style.transform = `translateY(-${window.scrollY * speed}px)`;
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="header-parallax">
      <div className="parallax">
        <div className="cloud-animation">
          <img src={cloud1} alt="Cloud" />
        </div>
        <div className="cloud-animation2">
          <img src={cloud2} alt="Cloud" />
        </div>
        <div className="parallax-layer layer1" data-speed="0.1"></div>
        <div className="parallax-layer layer2" data-speed="0.4"></div>
        <div className="parallax-layer layer3" data-speed="0.7"></div>
        <div className="parallax-layer logo" data-speed="0.1">
          <img className="solofarming-logo" src={logo} alt="logo" />
          <h3>The best Web3 farming game</h3>
          <div className="header-buttons">
            {/* <button className="button button-primary">Play now</button> */}
            <button
              className="button button-light"
              onClick={() =>
                window.open("https://docs.solofarming.xyz/", "_blank")
              }
            >
              Whitepaper
            </button>
          </div>
        </div>
      </div>
      <div className="ground-container">
        <div className="train">
          <img src={train} alt="Train" />
        </div>
        <img className="house" src={house} alt="house"></img>
        <div className="ground-left">
          <img src={groundLeft} alt=""></img>
        </div>

        <div className="ground">
          <div className="gifs-container">
            <img className="npc-farming" src={npcFarming} alt=""></img>
            <img className="npc-mining" src={npcMining} alt=""></img>
            <img className="npc-livestock" src={npcLivestock} alt=""></img>

            <img className="npc-fishing" src={npcFishing} alt=""></img>
          </div>
          <img src={ground} alt=""></img>
        </div>
        <div className="ground-right">
          <img src={groundRight} alt=""></img>
        </div>
      </div>
      <div className="ground-mobile">
        <img src={headerMobile} alt="header-mobile" />
      </div>
    </header>
  );
};

export default HeaderParallax;
