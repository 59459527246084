import React, { useState } from "react";
import "./Carousel.css";
import Container from "../container/Container";

interface CarouselProps {
  images: string[];
}

const Carousel = ({ images }: CarouselProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  return (
    <div className="carousel-container">
      <div className="gradient">
        <div className="border-top-wall"></div>
        <div className="section-content">
          <div className="carousel-slide">
            <Container>
              {images.map((image, index) => (
                <div
                  className={
                    index === currentImageIndex ? "slide active" : "slide"
                  }
                  key={index}
                >
                  {index === currentImageIndex && (
                    <img src={image} alt={`Slide ${index}`} className="image" />
                  )}
                </div>
              ))}
            </Container>
          </div>

          <div className="dot-container">
            {images.map((_, index) => (
              <span
                key={index}
                className={
                  index === currentImageIndex ? "dot active-dot" : "dot"
                }
                onClick={() => setCurrentImageIndex(index)}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
