import React from "react";
import "./SocialLinks.css";

function SocialLinks() {
  return (
    <div className="social-links">
      <div className="top-gram"></div>
      <div className="section-content social-links-container">
        <div className="content-info">
          <h1>Join the best Web3 Farming game</h1>
        </div>
        <div className="links-section">
          <div>
            <p>
              At Solo Farming, every player is a pioneer, and every move is a
              rewarding journey. Sign up now for updates and early access!
            </p>
          </div>
          <div className="links-container">
            <button
              className="button button-primary"
              onClick={() =>
                window.open("https://discord.gg/7f9zm76d", "_blank")
              }
            >
              Discord
            </button>
            <button
              className="button button-primary"
              onClick={() =>
                window.open("https://twitter.com/SoloFarmingxyz", "_blank")
              }
            >
              X
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialLinks;
