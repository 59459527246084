import React from "react";
import "./Roadmap.css";

import roadmap from "../assets/roadmap.png";

const Roadmap = () => {
  return (
    <div className="roadmap">
      <div className="section-content roadmap-container">
        <h1>Roadmap</h1>
        <img src={roadmap} alt="roadmap"></img>
      </div>
    </div>
  );
};
export default Roadmap;
