import React from "react";
import "./Skills.css";
import npcWatering from "../assets/npc-watering.gif";
import npcMining from "../assets/npc-mining.gif";
import npcLogging from "../assets/npc-logging.gif";
import npcLivestock from "../assets/npc-livestock.gif";
import npcFishing from "../assets/npc-fishing.gif";
import Container from "../container/Container";

function Item({
  title,
  description,
  imgSrc,
}: {
  title: string;
  description: string;
  imgSrc: any;
}) {
  return (
    <div className="item">
      <div className="item-content">
        <img src={imgSrc} alt="skill" />
        <h2>{title}</h2>
        <p className="item-description">{description}</p>
      </div>
    </div>
  );
}

const Skills = () => {
  return (
    <div className="skills section-content">
      <h1>Choose your masteries</h1>
      <h3> Adapt, strategize, and thrive in a dynamic Web3 farming!</h3>

      <div className="container-skills">
        <Container>
          <Item
            title="Farming"
            description="Choose from a wide variety of crops, each with its own growth requirements and market value."
            imgSrc={npcWatering}
          />
        </Container>
        <Container>
          <Item
            title="Mining"
            description="Mining is a crucial activity in Solo Farming, where players can extract valuable metals and gems from the rocks."
            imgSrc={npcMining}
          />
        </Container>
        <Container>
          <Item
            title="Logging"
            description="Harvesting wood from trees requires time and effort, but the rewards are plentiful. Use axes or other tools to fell trees and collect valuable wood logs for processing."
            imgSrc={npcLogging}
          />
        </Container>
      </div>
      <div className="section-content container-skills">
        <Container>
          <Item
            title="Husbandry"
            description="Through the careful rearing of animals, players can produce a variety of goods such as milk, eggs, and wool, essential for crafting and trade."
            imgSrc={npcLivestock}
          />
        </Container>
        <Container>
          <Item
            title="Fishing"
            description="Equip yourself with rods and chums to harvest a variety of fish. From crafting delicious meals to trading rare species, fishing will enriches your inventory."
            imgSrc={npcFishing}
          />
        </Container>
      </div>
    </div>
  );
};

export default Skills;
