import React from "react";
import "./App.css";
import "./fonts.css";
import HeaderParallax from "./header/HeaderParallax";
import Skills from "./skills/Skills";
import Carousel from "./carrossel/Carousel";
import Economy from "./economy/Economy";
import SocialLinks from "./sociallinks/SocialLinks";
import Footer from "./footer/Footer";
import Roadmap from "./roadmap/Roadmap";

function App() {
  return (
    <div className="App">
      <section>
        <HeaderParallax />
      </section>

      <section>
        <Skills />
      </section>
      <section>
        <Carousel
          images={[
            "https://bafybeia4asiohvcotowxekjhaylctnaqokh375ua7msqljzdpkehxnhqsy.ipfs.w3s.link/img1.png",
            "https://bafybeidph3rgdjxqvekemzrkjldmag63bcguvsblzhzn4hrr6hlpxkuwhu.ipfs.w3s.link/img2.png",
            "https://bafybeif6mdzan4sa6ezue42iy7er3jacf4k4tbnrpobmwgfcmog5aeo3r4.ipfs.w3s.link/img3.png",
          ]}
        />
      </section>
      <section>
        <Economy />
      </section>
      <section>
        <Roadmap />
      </section>
      <section>
        <SocialLinks />
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
}

export default App;
